import React from "react"
import { withStyles } from "@material-ui/core"
import Button from "@material-ui/core/Button"
//import { scroller } from "react-scroll"

function ContactUsButton(props) {
  const { classes } = props

  return (
    <div>
      <Button className={classes.buttonStyle}>
        Contact us
      </Button>
    </div>
  )

  /*function scrollToCallToActionBlock() {
    scroller.scrollTo("footerCallToActionBlock", {
      duration: 400,
      delay: 100,
      smooth: true,
      offset: -100,
    })
  }*/
}

const styles = theme => {
  return {
    buttonStyle: {
      height: 34,
      color: "#FFB533",
      border: "1px solid #FFB533",
      backgroundColor: "transparent",
      backgroundImage: "none",
      boxShadow: "none",
      padding: "5px 10px",
      fontWeight: 400,

      "&:hover": {
        background: "linear-gradient(180deg, rgba(255, 197, 51, 0.1) 0%, rgba(255, 187, 0, 0.1) 99.56%);",
        boxShadow: "none",
      },

      "& span": {
        color: "#FFB533",
      }
    },
  }
}

// eslint-disable-next-line
ContactUsButton = withStyles(styles)(ContactUsButton)

export { ContactUsButton }
