import React from "react"

function TextLogo({ className }) {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="129"
        height="13"
        viewBox="0 0 129 13"
      >
        <g
          fill="none"
          fillRule="evenodd"
        >
          <path d="M0-1h129v15H0z"/>
          <path
            fill="#FFF"
            fillRule="nonzero"
            d="M10.472 12.698l-.81-2.12H4.023l-.828 2.12H0L5.086.205h3.51l5.054 12.493h-3.178zm-3.63-9.835L4.79 8.24h4.101l-2.05-5.376zm9.083 8.073l1.518-2.021a5.918 5.918 0 0 0 4.182 1.683c1.538 0 2.276-.673 2.276-1.384 0-2.21-7.57-.693-7.57-5.395.017-2.077 1.908-3.8 5.005-3.8 2.09 0 3.826.598 5.126 1.742l-1.557 1.95a5.85 5.85 0 0 0-3.826-1.371c-1.202 0-1.875.507-1.875 1.255 0 1.985 7.553.65 7.553 5.32 0 2.275-1.716 4.007-5.265 4.007-2.522 0-4.338-.806-5.567-1.986zm17.44 1.762V2.548H29.52V.205h10.49v2.343h-3.825v10.15h-2.82zm17.335 0l-2.6-4.44h-2.05v4.44H43.25V.205H49.4c2.743 0 4.417 1.706 4.417 4.026A3.624 3.624 0 0 1 50.94 7.94l2.958 4.758H50.7zm.276-8.45c0-1.05-.848-1.684-1.972-1.684h-2.925v3.354h2.925c1.117 0 1.966-.637 1.966-1.693l.006.023zm16.136 8.45l-.812-2.12h-5.636l-.828 2.12H56.64L61.731.205h3.51l5.066 12.493h-3.195zm-3.626-9.835L61.425 8.24h4.098l-2.037-5.376zm9.815 9.835V.205h2.801v10.15h5.56v2.343H73.3zM84.106 6.46C84.107 2.697 87.006 0 90.97 0s6.88 2.697 6.88 6.461c0 3.764-2.899 6.461-6.88 6.461s-6.864-2.697-6.864-6.461zm10.865 0c0-2.34-1.577-4.101-4.001-4.101-2.425 0-3.985 1.761-3.985 4.101s1.557 4.101 3.985 4.101c2.427 0 4-1.787 4-4.101zm15.109 6.237l-.806-2.12h-5.639l-.829 2.12h-3.195L104.7.205h3.526l5.067 12.493h-3.211zm-3.627-9.835l-2.051 5.376h4.101l-2.05-5.376zm9.818 9.835V.205h5.2c4.121 0 6.978 2.493 6.978 6.256 0 3.764-2.857 6.237-6.978 6.237h-5.2zm9.308-6.237c0-2.21-1.42-3.9-4.121-3.9h-2.389v7.8h2.389c2.6-.007 4.121-1.784 4.121-3.9z"
          />
        </g>
      </svg>
    </div>
  )
}

export { TextLogo }