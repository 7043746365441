import React, { useState } from "react"
import { CustomLink } from "./custom-link"
import { Constants } from "./../constants"
import { withStyles } from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import { HeaderDrawer } from "./header-drawer"
import { LogoMobile } from "./svg/logo-mobile"
import { Logo } from "./svg/logo"
import { Link } from "gatsby"
import { ContactUsButton } from "./molecules/contact-us-button"

function Navigation({ classes, isBlackTheme = false, isHeader = false }) {
  const [isDrawerOpen, setDrawerIsOpen] = useState(false)
  return (
    <div className={classes.navigationContainer}>
      <div>{renderLogo()}</div>
      {renderNavigation()}
      {renderDrawer()}
    </div>
  )

  function renderLogo() {
    if (!isHeader) return null

    return (
      <Link to="https://astraloadhq.com/">
        <Logo className={classes.desktopLogo} />
        <LogoMobile className={classes.mobileLogo} />
      </Link>
    )
  }

  function renderDrawer() {
    return (
      <HeaderDrawer
        isOpen={isDrawerOpen}
        onClose={() => setDrawerIsOpen(false)}
        onOpen={() => setDrawerIsOpen(true)}
      />
    )
  }

  function renderMobileMenu() {
    return (
      <MenuIcon
        onClick={() => setDrawerIsOpen(true)}
        className={classes.mobileMenu}
      />
    )
  }

  function renderContactButton() {
    if (!isHeader) return null

    return <ContactUsButton/>
  }

  function renderNavigation() {
    return (
      <>
        {renderMobileMenu()}
        <div className={classes.linkContainer}>
          {renderNavigationLinks()}
          <CustomLink
            isBlackTheme={isBlackTheme}
            to="/"
            children="Blog"
            isGatsbyLink
          />
        </div>
      </>
    )
  }

  function renderNavigationLinks() {
    const { navigationLinks } = Constants
    return navigationLinks.map((linkItem, index) => (
      <CustomLink isBlackTheme={isBlackTheme} key={index} {...linkItem} />
    ))
  }
}

const linkContainer = {
  display: "flex",
}

const styles = theme => ({
  linkContainerFooter: {
    ...linkContainer,
  },
  linkContainer: {
    ...linkContainer,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileMenu: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  desktopMenu: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileLogo: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  desktopLogo: {
    display: "block",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  telegramIcon: {
    display: "flex",
    alignItems: "center",
    marginRight: "20px",
    padding: "7px",
    borderRadius: "8px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: ({ isBlackTheme }) =>
        isBlackTheme ? "rgba(16, 10, 35, 0.05)" : "rgba(255, 255, 255, 0.1)",
    },
    cursor: "pointer",
  },
  navigationContainer: {
    padding: ({ isHeader }) => (isHeader ? "30px 40px" : "0"),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start",
    },
  },
})

// eslint-disable-next-line
Navigation = withStyles(styles)(Navigation)

export { Navigation }
