import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

function useImagesQuery() {
  return useStaticQuery(graphql`
    query {
      spaceBackgroundImage: file(relativePath: { eq: "space-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }

      mainPagePrev: file(relativePath: { eq: "main_page_preview.png" }) {
        extension
        publicURL
      }
      
      services: file(relativePath: { eq: "services.png" }) {
        childImageSharp {
          fluid(maxWidth: 1540, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      
      designSystemTitle: file(relativePath: { eq: "title.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      
      designSystemTopImg: file(relativePath: { eq: "design-system-top-img.png" }) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
      
      astraloadFrontendTopImg: file(relativePath: { eq: "astraload-frontend-top-img.png" }) {
        childImageSharp {
          fluid(maxWidth: 3840, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
    }
  `)
}

function GatsbyImageBackground({ alt, name = "", children, className }) {
  const image = useImagesQuery()
  return (
    <BackgroundImage
      className={className}
      fluid={image[name]?.childImageSharp?.fluid}
      alt={alt}
      fadeIn="soft"
      critical={false}
    >
      {children}
    </BackgroundImage>
  )
}

function useFluidUrl(name) {
  const image = useImagesQuery()
  return image[name]?.childImageSharp?.fluid
}

export {
  GatsbyImageBackground,
  useFluidUrl
}
