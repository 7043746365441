import * as React from "react"

const LinkedinLink = ({ color = "#FFFFFF", className, ...props }) => (
  <a
    href="https://www.linkedin.com/company/astraload"
    className={className}
    target="__blank"
  >
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 2.22144C0 1.57753 0.225232 1.04632 0.675676 0.627798C1.12612 0.209259 1.71172 0 2.43243 0C3.14029 0 3.71299 0.206034 4.15058 0.618139C4.60102 1.04311 4.82625 1.59685 4.82625 2.27939C4.82625 2.89753 4.60747 3.41263 4.16988 3.82474C3.71944 4.24971 3.12741 4.46219 2.39382 4.46219H2.37452C1.66666 4.46219 1.09396 4.24971 0.656371 3.82474C0.218784 3.39977 0 2.86533 0 2.22144ZM0.250965 19.1237V6.22003H4.53668V19.1237H0.250965ZM6.9112 19.1237H11.1969V11.9185C11.1969 11.4678 11.2484 11.1201 11.3514 10.8754C11.5315 10.4375 11.805 10.0673 12.1718 9.76467C12.5386 9.46203 12.9987 9.31072 13.5521 9.31072C14.9936 9.31072 15.7143 10.283 15.7143 12.2276V19.1237H20V11.7253C20 9.81939 19.5496 8.37385 18.6486 7.38869C17.7477 6.40354 16.5573 5.91096 15.0772 5.91096C13.417 5.91096 12.1236 6.62568 11.1969 8.05513V8.09376H11.1776L11.1969 8.05513V6.22003H6.9112C6.93693 6.63211 6.94981 7.91346 6.94981 10.0641C6.94981 12.2147 6.93693 15.2345 6.9112 19.1237Z"
        fill={color}
      />
    </svg>
  </a>
)

export { LinkedinLink }
