import * as React from "react"

function ColoredLogo(props) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 65 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30.8867 105.425V39.6365L5.2784 57.118C2.53673 58.988 2.7784 62.9142 5.47007 64.8349C10.2249 68.1621 14.307 72.3846 17.4951 77.2734C22.5951 84.8042 24.9284 96.608 27.1951 105.89C27.2558 106.387 27.5086 106.84 27.8977 107.147C28.2869 107.455 28.7805 107.594 29.2701 107.532C29.7596 107.47 30.205 107.213 30.5081 106.818C30.8113 106.423 30.9475 105.922 30.8867 105.425Z"
        fill="#640099"
      />
      <path
        d="M20.0283 43.1395C23.273 43.1395 25.9033 40.4687 25.9033 37.1741C25.9033 33.8795 23.273 31.2087 20.0283 31.2087C16.7836 31.2087 14.1533 33.8795 14.1533 37.1741C14.1533 40.4687 16.7836 43.1395 20.0283 43.1395Z"
        fill="url(#paint0_linear_6380_19850)"
      />
      <path
        d="M60.9365 17.6956C60.9385 14.8838 60.1696 12.1269 58.7158 9.7336C57.2621 7.34035 55.1809 5.40526 52.7054 4.14501C50.2298 2.88476 47.4576 2.34912 44.6993 2.59805C41.9409 2.84699 39.3052 3.87069 37.0874 5.55451C34.8695 7.23832 33.1571 9.51578 32.1418 12.1319C31.1266 14.7479 30.8485 17.5993 31.3389 20.3668C31.8293 23.1342 33.0687 25.7084 34.9183 27.8011C36.7679 29.8938 39.1548 31.4223 41.8115 32.2156L12.8115 51.9733V105.416C12.8157 105.883 12.9892 106.332 13.2988 106.676C13.6084 107.021 14.0323 107.238 14.4895 107.285C14.9466 107.332 15.4048 107.206 15.7764 106.932C16.148 106.657 16.4067 106.253 16.5032 105.797C19.3699 91.4125 24.7532 71.731 29.3032 61.5349C33.1032 53.0225 39.4032 44.1041 46.4782 35.651C47.3451 34.5936 48.3717 33.6825 49.5199 32.9518C51.4199 31.9449 52.8532 31.141 53.1282 31.0056H53.1782C55.5298 29.6995 57.4914 27.7741 58.8567 25.432C60.2219 23.0898 60.9404 20.4173 60.9365 17.6956Z"
        fill="url(#paint1_linear_6380_19850)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6380_19850"
          x1={27.445}
          y1={29.6434}
          x2={12.3429}
          y2={44.5166}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ED1C24" />
          <stop offset={1} stopColor="#842AD0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6380_19850"
          x1={32.4949}
          y1={-13.4436}
          x2={-5.93041}
          y2={-4.71332}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ED1C24" />
          <stop offset={1} stopColor="#842AD0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export { ColoredLogo }
