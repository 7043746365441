import React from "react"
import { DrawerLink } from "./drawer-link"
import { TelegramLink } from "./svg/telegram-link"
import { LinkedinLink } from "./svg/linkedin-link"
import { CustomLink } from "./custom-link"
import { Constants } from "../constants"
import { withStyles } from "@material-ui/core"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import { TextLogo } from "./svg/text-logo"
import ArrowForward from "@material-ui/icons/ArrowForward"
import withWidth from "@material-ui/core/withWidth/withWidth"

function HeaderDrawer(props) {
  const { classes, isOpen, onClose, onOpen, width, className } = props

  const drawerClasses = { paper: classes.drawerContainer, root: className }
  const isNotMobile = width !== "xs"
  return (
    <SwipeableDrawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      classes={drawerClasses}
      disableSwipeToOpen={isNotMobile}
    >
      <div className={classes.contentContainer}>
        <div className={classes.logoContainer}>
          <TextLogo />
          <div onClick={onClose} aria-hidden="true">
            <ArrowForward className={classes.arrowForward} />
          </div>
        </div>

        <div className={classes.linksContainer}>
          {renderNavigationLinks()}
          <div>
            <CustomLink
              isBlackTheme={false}
              to="/"
              children="Blog"
              isGatsbyLink
            />
          </div>
        </div>
      </div>
      <div className={`${classes.contentContainer} ${classes.containerFooter}`}>
        <div className={classes.socialContainer}>
          <TelegramLink className={classes.iconLink} />
          <LinkedinLink className={classes.iconLink} />
        </div>
        <div className={classes.address}>
          919 North Market Street, Suite 950, Wilmington, New Castle, 19801
        </div>
        <div className={classes.contacts}>
          <a href="tel:+13022615351" className={classes.contactItem}>
            +1-302-261-5351
          </a>
          <a href="mailto:team@astraloadhq.com" className={classes.contactItem}>
            team@astraloadhq.com
          </a>
        </div>
      </div>
    </SwipeableDrawer>
  )

  function renderNavigationLinks() {
    const { navigationLinks } = Constants
    return navigationLinks.map((linkItem, index) => (
      <DrawerLink key={index} {...linkItem} />
    ))
  }
}

const styles = theme => ({
  drawerContainer: {
    display: "flex",
    padding: "30px",
    width: "70vw",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundImage: "linear-gradient(180deg, #BF31D7 0%, #4D18DD 100%)",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  linksContainer: {
    display: "flex",
    flexDirection: "column",
    color: "#FFF !important",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "38px",
  },
  arrowForward: {
    fill: "#FFFFFF !important",
    cursor: "pointer",
  },
  containerFooter: {
    maxWidth: "220px",
    paddingBottom: "60px", // compensate mui-drawer negative position
    justifyContent: "flex-end",
    fontSize: "18px",
    lineHeight: "24px",
    color: "#FFFFFF",
  },
  socialContainer: {
    display: "flex",
    marginBottom: "40px",
  },
  address: {
    marginBottom: "20px",
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: "400",
    color: "#FFFFFF",
  },
  contactItem: {
    display: "block",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "24px",
    textDecoration: "none",
    boxShadow: "none",
    color: "#FFFFFF",
  },
  iconLink: {
    display: "flex",
    marginRight: "20px",
    padding: "7px",
    borderRadius: "8px",
    alignItems: "center",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: ({ linkColor }) =>
        linkColor ? "rgba(16, 10, 35, 0.1)" : "rgba(255, 255, 255, 0.1)",
    },
    cursor: "pointer",
  },
})

// eslint-disable-next-line
HeaderDrawer = withWidth()(withStyles(styles)(HeaderDrawer))

export { HeaderDrawer }
