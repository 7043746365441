const Constants = {
  navigationLinks: [
    {
      to: "https://astraloadhq.com/astraload-perf-team/",
      children: "Perf team",
    },
    {
      to: "https://astraloadhq.com/astraload-frontend",
      children: "Frontend program",
    },
    {
      to: "https://astraloadhq.com/astraload-software-development",
      children: "Software Development"
    },
    {
      to: "https://astraloadhq.com/astraload-saas/",
      children: "SAAS",
    },
  ],

  keyWords:
    "meteor load testing, meteor performance, single page application load testing, SPA load testing, load testing tools, load testing software,  meteor, graphql,  meteor and graphql, scale single page application, performance optimization of web apps",
}

export { Constants }
