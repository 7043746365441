import React from "react"
import { withStyles } from "@material-ui/core"
import { Link } from "gatsby"

function CustomLink({ classes, to, children, isGatsbyLink }) {
  if (isGatsbyLink)
    return (
      <Link to={to} className={`${classes.link} ${classes.activeLinkStyle}`}>
        {children}
      </Link>
    )

  return (
    <a href={to} className={classes.link}>
      {children}
    </a>
  )
}

const styles = theme => ({
  link: {
    display: "inline-block",
    padding: "5px 10px",
    color: ({ isBlackTheme }) =>
      isBlackTheme ? theme.palette.text.primary : "#fff",
    fontSize: "18px",
    fontFamily: "Europa, sans-serif",
    textDecoration: "none",
    fontWeight: "400",
    backgroundColor: "transparent",
    borderRadius: "8px",
    lineHeight: "1.35",
    boxShadow: "none",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: ({ isBlackTheme }) =>
        isBlackTheme ? "rgba(16, 10, 35, 0.05)" : "rgba(255, 255, 255, 0.1)",
    },
    "&:not(:last-child)": {
      marginRight: "20px",
    },
  },
  activeLinkStyle: {
    backgroundColor: ({ isBlackTheme }) =>
      isBlackTheme
        ? "rgba(16, 10, 35, 0.1) !important"
        : "rgba(255, 255, 255, 0.1) !important",
    "&:hover": {
      opacity: "1",
    },
  },
})

// eslint-disable-next-line
CustomLink = withStyles(styles)(CustomLink)

export { CustomLink }
