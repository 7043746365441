import React from "react"

function LogoMobile({ className }) {
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="50" viewBox="0 0 30 50" fill="none">
        <path d="M14.2559 47.9214V18.0176L2.43671 25.9637C1.17133 26.8137 1.28286 28.5983 2.52517 29.4714C4.7197 30.9838 6.60375 32.9031 8.07517 35.1253C10.429 38.5483 11.5059 43.9137 12.5521 48.133C12.5801 48.3589 12.6968 48.5644 12.8764 48.7044C13.056 48.8443 13.2838 48.9072 13.5098 48.8791C13.7357 48.8511 13.9413 48.7344 14.0812 48.5548C14.2211 48.3752 14.284 48.1474 14.2559 47.9214Z" fill="#640099"/>
        <path d="M9.24279 19.6086C10.7403 19.6086 11.9543 18.3946 11.9543 16.8971C11.9543 15.3995 10.7403 14.1855 9.24279 14.1855C7.74525 14.1855 6.53125 15.3995 6.53125 16.8971C6.53125 18.3946 7.74525 19.6086 9.24279 19.6086Z" fill="url(#paint0_linear_1277_1700)"/>
        <path d="M28.1256 8.04387C28.1265 6.76575 27.7716 5.51261 27.1007 4.42476C26.4297 3.33692 25.4692 2.45734 24.3266 1.8845C23.184 1.31165 21.9046 1.06818 20.6315 1.18133C19.3584 1.29449 18.1419 1.7598 17.1183 2.52517C16.0947 3.29055 15.3043 4.32576 14.8357 5.51488C14.3672 6.704 14.2388 8.00009 14.4652 9.25801C14.6915 10.5159 15.2635 11.686 16.1172 12.6372C16.9709 13.5885 18.0725 14.2833 19.2987 14.6439L5.91406 23.6246V47.9169C5.91598 48.129 5.99604 48.3329 6.13894 48.4896C6.28183 48.6464 6.4775 48.7449 6.6885 48.7663C6.89949 48.7877 7.11098 48.7306 7.28247 48.6058C7.45395 48.481 7.57339 48.2974 7.61791 48.09C8.94099 41.5516 11.4256 32.6054 13.5256 27.9708C15.2794 24.1016 18.1871 20.0477 21.4525 16.2054C21.8526 15.7247 22.3265 15.3106 22.8564 14.9785C23.7333 14.5208 24.3948 14.1554 24.5218 14.0939H24.5448C25.6302 13.5002 26.5355 12.625 27.1657 11.5604C27.7958 10.4957 28.1274 9.28099 28.1256 8.04387Z" fill="url(#paint1_linear_1277_1700)"/>
        <defs>
          <linearGradient id="paint0_linear_1277_1700" x1="12.6659" y1="13.474" x2="5.80048" y2="20.3394" gradientUnits="userSpaceOnUse">
            <stop stop-color="#ED1C24"/>
            <stop offset="1" stop-color="#842AD0"/>
          </linearGradient>
          <linearGradient id="paint1_linear_1277_1700" x1="14.9987" y1="-6.11032" x2="-2.70911" y2="-2.0252" gradientUnits="userSpaceOnUse">
            <stop stop-color="#ED1C24"/>
            <stop offset="1" stop-color="#842AD0"/>
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}

export { LogoMobile }
