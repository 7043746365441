import React from "react"
import { withStyles } from "@material-ui/core"
import { Navigation } from "./navigation"
import { GatsbyImageBackground } from "../images"


function Header(props) {
  const {
    classes,
    background,
    onlyBackground,
  } = props

  const backgroundClassName = onlyBackground ? classes.rectangle : classes.triangle
  return (
    <header style={{ position: "relative" }}>
      <div className={classes.navigationContainer}>
        <Navigation isBlackTheme={true} isHeader={true}/>
      </div>
    </header>
  )
}

const styles = (theme) => {
  return {
    ...theme.fadeIn,
    navigationContainer: {
      top: "0",
      width: "100%",
    },
    rectangle: {
      height: "100vh",
      width: "100%",
    },
    triangle: {
      width: "100%",
      height: "206px",
      position: "relative",
      clipPath: "polygon(100% 0, 100% -63%, 0 100%, 0 100%, 0 0)",
      '-webkit-clip-path': 'polygon(100% 0, 100% -63%, 0 100%, 0 100%, 0 0)',
      [theme.breakpoints.down("sm")]: {
        height: "220px",
        clipPath: "polygon(100% 0, 100% 44%, 0 100%, 0 100%, 0 0);",
        "-webkit-clip-path": "polygon(100% 0, 100% 44%, 0 100%, 0 100%, 0 0)",
      },
      [theme.breakpoints.down("xs")]: {
        height: "126px",
        clipPath: "polygon(100% 0, 100% 44%, 0 100%, 0 100%, 0 0)",
        "-webkit-clip-path": "polygon(100% 0, 100% 44%, 0 100%, 0 100%, 0 0)",
      },
    },
    buttonStyle: {
      minWidth: "186px",
      animation: "$fadein 3000ms normal forwards",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    buttonContainer: {
      ...theme.defaultContainer,
      display: "flex",
      justifyContent: "center",
      marginTop: "19px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "44px",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "0",
      },
    },
    mobileButtonText: {
      display: "none",
      [theme.breakpoints.down("xs")]: {
        display: "block",
      },
    },
    desktopButtonText: {
      display: "block",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
  }
}

// eslint-disable-next-line
Header = withStyles(styles)(Header)

export { Header }
