import * as React from "react"

const TwitterLink = ({ color = "#FFFFFF", className, ...props }) => (
  <a
    href="https://twitter.com/astraload"
    className={className}
    target="__blank"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M20 3.9365C19.2642 4.26603 18.4718 4.48572 17.643 4.58743C18.492 4.07482 19.1389 3.26524 19.4461 2.30512C18.6537 2.77297 17.7764 3.11878 16.8425 3.30185C16.0946 2.5004 15.0273 2 13.8468 2C11.5787 2 9.74328 3.847 9.74328 6.12931C9.74328 6.45477 9.77966 6.76803 9.84839 7.06908C6.44027 6.89821 3.41621 5.25463 1.39074 2.7567C1.03901 3.36694 0.836871 4.07482 0.836871 4.83152C0.836871 6.26356 1.56054 7.52879 2.6602 8.26922C1.98908 8.24888 1.35436 8.06174 0.800485 7.75255C0.800485 7.76882 0.800485 7.7851 0.800485 7.80544C0.800485 9.80703 2.21548 11.475 4.09137 11.8534C3.74773 11.9469 3.38387 11.9998 3.01193 11.9998C2.74914 11.9998 2.4904 11.9754 2.23974 11.9266C2.76127 13.5661 4.27734 14.7622 6.07237 14.7947C4.6695 15.9013 2.89873 16.5604 0.978371 16.5604C0.646857 16.5604 0.319385 16.54 0 16.5034C1.81524 17.6751 3.97413 18.3586 6.29068 18.3586C13.8387 18.3586 17.9664 12.069 17.9664 6.60936C17.9664 6.43036 17.9624 6.25135 17.9543 6.07642C18.7548 5.49059 19.4502 4.76236 20 3.9365Z"
            fill={color}
      />
    </svg>
  </a>
)

export { TwitterLink }
