import React from "react"
import { Footer } from "./footer"
import { Header } from "./header"

class Layout extends React.Component {
  render() {
    const { children } = this.props
    return (
      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <Header />
        <main>{children}</main>
        <Footer />
      </div>
    )
  }
}

export default Layout
